import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import NavigationBar from '../components/NavigationBar';
import ActivityConfirmation from '../components/questionnaire/0_ActivityConfirmation';
import RevenueRangeSelector from '../components/questionnaire/1_RevenueRangeSelector';
import EmployeesRangeSelector from '../components/questionnaire/2_EmployeesRangeSelector';
import { useTranslation } from "react-i18next";
import RegimeTva from '../components/questionnaire/3_RegimeTva';
import EprSelector from '../components/questionnaire/4_EprSelector';
import PersonnalDataSelector from '../components/questionnaire/5_PersonnalDataSelector';
import DateClosed from '../components/questionnaire/6_date_closed';
import EquipmentSelector from '../components/questionnaire/8_equipment';
import DataSubContractor from '../components/questionnaire/9_DataSubcontractor';
import SujecteToTva from '../components/questionnaire/10_SujecteToTva';
import CollectionDonnesPerso from '../components/questionnaire/11_CollectionDonnesPerso';
import CriteresDesignationDPO from '../components/questionnaire/12_ActivityBase';
import DetentionSiteInternet from '../components/questionnaire/13_DetentionSiteInternet';
import UsedTraceurCookies from '../components/questionnaire/14_UsedTraceurCookies';
import ProdDetDechets from '../components/questionnaire/15_ProdDetDechets';
import ProdDetDechets5flux from '../components/questionnaire/16_SocieteFilialeMere';
import VehiculeDeSociete from '../components/questionnaire/17_VehiculeDeSociete';
import ContractantPrestationSup from '../components/questionnaire/18_ContractantPrestationSup';
import VersementPayeType from '../components/questionnaire/19_VersementPayeType';
import TypeContratsTravail from '../components/questionnaire/20_TypeContratsTravail';
import TotalBilan from '../components/questionnaire/21_TotalBilan';
import TotalCapitauxPropres from '../components/questionnaire/22_totalCapitauxPropres';
import CapitalSocial from '../components/questionnaire/23_CapitalSocial';
import EmissionFactureSecteurPublic from '../components/questionnaire/24_EmissionFactureSecteurPublic';
import TvaDeclareSup15k from '../components/questionnaire/25_TvaDeclareSup15k';
import InterventionConvReglSASSCASA from '../components/questionnaire/26_InterventionConvReglSASSCASA';
import SurfaceExploitationSup1000m2 from '../components/questionnaire/11_5_SurfaceExploitationSup1000m2';
import OptionImpotSocieteISQ from '../components/questionnaire/6_SCI0_optionImpotSocieteISQ';
import TraitementListeAIPDoblig from '../components/questionnaire/9_1_traitementListeAIPDoblig';
import OperateurPlateforme from '../components/questionnaire/9_1_operateurPlateforme';
import LocMeubleeNonMeubleeQ from '../components/questionnaire/9_0_locMeubleeNonMeubleeQ';
import { Spinner } from "flowbite-react";
import LocMeubleeOccasMoins10p from '../components/questionnaire/10_1_locMeubleeOccasMoins10p';
import ImpositionAssociesQ from '../components/questionnaire/11_1_impositionAssociesQ';
import ProprioBatimentImmoQ from '../components/questionnaire/11_2_ProprioBatimentImmoQ';
import LocationMeubleDeTourismeQ from '../components/questionnaire/11_3_LocationMeubleDeTourismeQ';
import LocBiensImmoUsagePro from '../components/questionnaire/11_4_locBiensImmoUsagePro';
import LoyerProprioBailleur from '../components/questionnaire/7_loyerProprioBailleur';
import AssujettissementLoyerProComTvaQ from '../components/questionnaire/12_assujettissementLoyerProComTvaQ';
import LocHabitPersoProprioQ from '../components/questionnaire/11_6_locHabitPersoProprioQ';
import ClassementLogementDPE from '../components/questionnaire/24_classementLogementDPE';
import SiegeLocauxEnCommunX from '../components/questionnaire/30_SiegeLocauxEnCommunX';
import OptionRegimeMicroEntrepriseQ from '../components/questionnaire/4_OptionRegimeMicroEntrepriseQ';
import PeriodiciteDeclarationCA from '../components/questionnaire/6_PeriodiciteDeclarationCA';
import RevenuFiscalReference from '../components/questionnaire/7_RevenuFiscalReference';
import VersementLiberatoireIR from '../components/questionnaire/8_VersementLiberatoireIR';
import DateDomiciliationLocauxCommun from '../components/questionnaire/10_DateDomiciliationLocauxCommun';
import ProdDetenCollecteEliminDechetsQ from '../components/questionnaire/21_prodDetenCollecteEliminDechetsQ';
import VersHonorairesComSup1200 from '../components/questionnaire/26_VersHonorairesComSup1200';
import TypeControlExerceSociete from '../components/questionnaire/33_typeControlExerceSociete';
import DepasSeuilConsoCA48mBi24mEff250Q from '../components/questionnaire/34_depasSeuilConsoCA48mBi24mEff250Q';
import InterventionConvReglSARL from '../components/questionnaire/36_interventionConvReglSARL';
import NatureConvRegleIntevenues from '../components/questionnaire/37_natureConvRegleIntevenues';
import ExpositionTravailleurs from '../components/questionnaire/41_expositionTravailleurs';
import NbSalarieExpositionPenibilite from '../components/questionnaire/42_nbSalarieExpositionPenibilite';
import ControleRepoasHebdomadaire from '../components/questionnaire/43_controleRepoasHebdomadaire';
import InterventionEntrepriseExterieure from '../components/questionnaire/44_interventionEntrepriseExterieure';
import LoginComponent from '../components/LoginComponent';
// import { useLocation } from 'react-router-dom';

const SiretPage = () => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [activityDetails, setActivityDetails] = useState(null);
    const [isMicroEntreprise, setIsMicroEntreprise] = useState(false);
    const [isSCI, setIsSCI] = useState(false);
    const [isComanyCommercial, setIsComanyCommercial] = useState(false);
    const [raisonSociale, setRaisonSociale] = useState('###');
    const { t } = useTranslation();

    const [SIRET, setSIRET] = useState('');
    const [NAFsection, setNAFsection] = useState('')
    const [dateCreation, setDateCreation] = useState('');
    // Variable Questionnaire 
    const [chiffredAffairesTotalNet, setChiffredAffairesTotalNet] = useState(null);                // 1
    const [nbMoyenSalaries, setNbMoyenSalaries] = useState(null);                                  // 2
    const [regimeTva, setRegimeTva] = useState('');                                                // 3
    const [optionImpotSocieteISQ, setOptionImpotSocieteISQ] = useState(null);                      // 4 if sci 
    const [optionRegimeMicroEntrepriseQ, setOptionRegimeMicroEntrepriseQ] = useState(true);        // 4 if micro entreprise  
    const [proprioBatimentImmoQ, setProprioBatimentImmoQ] = useState([]);                          // 5
    const [auMoins90pCAsoumisTVA, setAuMoins90pCAsoumisTVA] = useState(true);                          // 5
    const [equipInstallationsSoumisVGPQ, setEquipInstallationsSoumisVGPQ] = useState([]);          // 6
    const [periodiciteDeclarationCA, setPeriodiciteDeclarationCA] = useState('mensuelle');       // 6 if micro entreprise  
    const [montantTVAdeclareSup15kQ, setMontantTVAdeclareSup15kQ] = useState(null);                // 6 if commercial company  
    const [loyerProprioBailleur, setLoyerProprioBailleur] = useState(true);                        // 7
    const [revenuFiscalReference, setRevenuFiscalReference] = useState(true);                      // 7 if micro entreprise
    const [locMeubleeNonMeubleeQ, setLocMeubleeNonMeubleeQ] = useState([]);                        // 8
    const [versementLiberatoireIR, setVersementLiberatoireIR] = useState(false);                   // 8 if micro entreprise
    const [locMeubleeOccasMoins10p, setLocMeubleeOccasMoins10p] = useState(false);                 // 9
    const [locationMeubleDeTourismeQ, setLocationMeubleDeTourismeQ] = useState(false);             // 10
    const [dateDomiciliationLocauxCommun, setDateDomiciliationLocauxCommun] = useState(null);      // 10 if micro entreprise
    const [locHabitPersoProprioQ, setLocHabitPersoProprioQ] = useState(false);                     // 11
    const [assujettissementLoyerProComTvaQ, setAssujettissementLoyerProComTvaQ] = useState('');    // 12
    const [surfaceExploitationSup1000mC, setSurfaceExploitationSup1000mC] = useState(null);        // 13
    const [emissionFactureSecteurPublic, setEmissionFactureSecteurPublic] = useState(false);       // 13 if micro entreprise
    const [locBiensImmoUsagePro, setLocBiensImmoUsagePro] = useState([]);                          // 14
    const [impositionAssociesQ, setImpositionAssociesQ] = useState([]);                            // 15
    const [traitementDonneesPerso, setTraitementDonneesPerso] = useState(true);                    // 16
    const [sousTraitantDonneesPersonnelles, setSousTraitantDonneesPersonnelles] = useState(false); // 17
    const [collectionDonnesPerso, setCollectionDonnesPerso] = useState(true);                      // 18
    const [traitementListeAIPDoblig, setTraitementListeAIPDoblig] = useState(false);               // 19
    const [criteresDesignationDPO, setCriteresDesignationDPO] = useState(false);                   // 20
    const [etabliRecevantPublicERP, setEtabliRecevantPublicERP] = useState("NON");                 // 21
    const [prodDetenCollecteEliminDechetsQ, setProdDetenCollecteEliminDechetsQ] = useState([])     // 21 if commercial
    const [prodDetDechets5flux, setProdDetDechets5flux] = useState(null);                          // 22
    const [cocontractantPrestationSup5k, setCocontractantPrestationSup5k] = useState(true);        // 23
    const [classementLogementDPE, setClassementLogementDPE] = useState([]);                        // 24
    const [detentionSiteInternetQ, setDetentionSiteInternetQ] = useState(true);                    // 25
    const [utilisationTraceurCookies, setUtilisationTraceurCookies] = useState(true);              // 26
    const [versHonorairesComSup1200, setVersHonorairesComSup1200] = useState(true);
    const [operateurPlateforme, setOperateurPlateforme] = useState([]);                            // 27
    const [datePremClotureX, setDatePremClotureX] = useState(null);                                // 28
    const [totalBilanQ, setTotalBilanQ] = useState(null);                                          // 29
    const [siegeLocauxEnCommunX, setSiegeLocauxEnCommunX] = useState(false);                       // 30
    const [totalCapitauxPropres, setTotalCapitauxPropres] = useState(null);                        // 30  if commercial
    const [capitalSocial, setCapitalSocial] = useState(null);                                      // 31  if commercial
    const [societeFilialeMereQ, setSocieteFilialeMereQ] = useState([])                             // 32  if commercial
    const [typeControlExerceSociete, setTypeControlExerceSociete] = useState([])                   // 33  if commercial
    const [depasSeuilConsoCA48mBi24mEff250Q, setDepasSeuilConsoCA48mBi24mEff250Q] = useState(null) // 34  if commercial
    const [interventionConvReglSASSCASA, setInterventionConvReglSASSCASA] = useState(false);       // 35  if commercial
    const [interventionConvReglSARL, setInterventionConvReglSARL] = useState(false);               // 36  if commercial
    const [natureConvRegleIntevenues, setNatureConvRegleIntevenues] = useState(false);             // 37  if commercial
    const [voitureVehiculeDeSocieteQ, setVoitureVehiculeDeSocieteQ] = useState(false);             // 38  if commercial
    const [versementPayeType, setVersementPayeType] = useState(null)                              // 39  if commercial //TOTO
    const [typeContratsTravail, setTypeContratsTravail] = useState(['CDI'])                        // 40  if commercial
    const [expositionTravailleurs, setExpositionTravailleurs] = useState([])                       // 41  if commercial
    const [nbSalarieExpositionPenibilite, setNbSalarieExpositionPenibilite] = useState(null)       // 42  if commercial
    const [controleRepoasHebdomadaire, setControleRepoasHebdomadaire] = useState(false)            // 43  if commercial
    const [interventionEntrepriseExterieure, setInterventionEntrepriseExterieure] = useState(false)            // 44  if commercial



    const [codeNaf, setCodeNaf] = useState('')
    const [showSpinner, setShowSpinner] = useState(false);

    const [currentStep, setCurrentStep] = useState('activityInput');
    const [stepHistory, setStepHistory] = useState([]);

    const [nbCandtionMicroValide, setNbCandtionMicroValide] = useState(0);

    const [NAFgroupe, setNAFgroupe] = useState('');
    const nbStep = stepHistory.length - 1;

    // Utilisation d'un objet vide pour suivre l'état des étapes validées
    const [validatedSteps, setValidatedSteps] = useState({});

    const [catJuridiqueNiv1, setCatJuridiqueNiv1] = useState('');
    const [catJuridiqueNiv2, setCatJuridiqueNiv2] = useState('');
    const [catJuridiqueNiv3, setCatJuridiqueNiv3] = useState('');


    // Fonction générique pour valider une étape
    const handleValidateStep = (step) => {
        // Si l'étape n'a pas encore été validée, on l'ajoute et on incrémente
        if (!validatedSteps[step]) {
            setNbCandtionMicroValide((prevValue) => prevValue + 1);
            setValidatedSteps((prevSteps) => ({
                ...prevSteps,
                [step]: true, // On marque l'étape comme validée
            }));
        }
    };

    const saveState = () => {
        const state = {
            inputValue,
            suggestions,
            currentStep,
            activityDetails,
            isMicroEntreprise,
            isSCI,
            isComanyCommercial,
            raisonSociale,
            SIRET,
            NAFsection,
            dateCreation,
            chiffredAffairesTotalNet,
            nbMoyenSalaries,
            regimeTva,
            optionImpotSocieteISQ,
            optionRegimeMicroEntrepriseQ,
            proprioBatimentImmoQ,
            auMoins90pCAsoumisTVA,
            equipInstallationsSoumisVGPQ,
            periodiciteDeclarationCA,
            montantTVAdeclareSup15kQ,
            loyerProprioBailleur,
            revenuFiscalReference,
            locMeubleeNonMeubleeQ,
            versementLiberatoireIR,
            locMeubleeOccasMoins10p,
            locationMeubleDeTourismeQ,
            dateDomiciliationLocauxCommun,
            locHabitPersoProprioQ,
            assujettissementLoyerProComTvaQ,
            surfaceExploitationSup1000mC,
            emissionFactureSecteurPublic,
            locBiensImmoUsagePro,
            impositionAssociesQ,
            traitementDonneesPerso,
            sousTraitantDonneesPersonnelles,
            collectionDonnesPerso,
            traitementListeAIPDoblig,
            criteresDesignationDPO,
            etabliRecevantPublicERP,
            prodDetenCollecteEliminDechetsQ,
            prodDetDechets5flux,
            cocontractantPrestationSup5k,
            classementLogementDPE,
            detentionSiteInternetQ,
            utilisationTraceurCookies,
            versHonorairesComSup1200,
            operateurPlateforme,
            datePremClotureX,
            totalBilanQ,
            siegeLocauxEnCommunX,
            totalCapitauxPropres,
            capitalSocial,
            societeFilialeMereQ,
            typeControlExerceSociete,
            depasSeuilConsoCA48mBi24mEff250Q,
            interventionConvReglSASSCASA,
            interventionConvReglSARL,
            natureConvRegleIntevenues,
            voitureVehiculeDeSocieteQ,
            versementPayeType,
            typeContratsTravail,
            expositionTravailleurs,
            nbSalarieExpositionPenibilite,
            controleRepoasHebdomadaire,
            interventionEntrepriseExterieure,
            codeNaf,
            nbCandtionMicroValide,
            validatedSteps,
            catJuridiqueNiv1,
            catJuridiqueNiv2,
            catJuridiqueNiv3,
            stepHistory
        };
        localStorage.setItem('questionnaireState', JSON.stringify(state));
    };

    useEffect(() => {
        loadState();
    }, []);

    const loadState = () => {
        const savedState = localStorage.getItem('questionnaireState');
        if (savedState) {
            const state = JSON.parse(savedState);
            setInputValue(state.inputValue);
            setSuggestions(state.suggestions);
            setCurrentStep(state.currentStep);
            setActivityDetails(state.activityDetails);
            setIsMicroEntreprise(state.isMicroEntreprise);
            setIsSCI(state.isSCI);
            setIsComanyCommercial(state.isComanyCommercial);
            setRaisonSociale(state.raisonSociale);
            setSIRET(state.SIRET);
            setNAFsection(state.NAFsection);
            setDateCreation(state.dateCreation);
            setChiffredAffairesTotalNet(state.chiffredAffairesTotalNet);
            setNbMoyenSalaries(state.nbMoyenSalaries);
            setRegimeTva(state.regimeTva);
            setOptionImpotSocieteISQ(state.optionImpotSocieteISQ);
            setOptionRegimeMicroEntrepriseQ(state.optionRegimeMicroEntrepriseQ);
            setProprioBatimentImmoQ(state.proprioBatimentImmoQ);
            setAuMoins90pCAsoumisTVA(state.auMoins90pCAsoumisTVA);
            setEquipInstallationsSoumisVGPQ(state.equipInstallationsSoumisVGPQ);
            setPeriodiciteDeclarationCA(state.periodiciteDeclarationCA);
            setMontantTVAdeclareSup15kQ(state.montantTVAdeclareSup15kQ);
            setLoyerProprioBailleur(state.loyerProprioBailleur);
            setRevenuFiscalReference(state.revenuFiscalReference);
            setLocMeubleeNonMeubleeQ(state.locMeubleeNonMeubleeQ);
            setVersementLiberatoireIR(state.versementLiberatoireIR);
            setLocMeubleeOccasMoins10p(state.locMeubleeOccasMoins10p);
            setLocationMeubleDeTourismeQ(state.locationMeubleDeTourismeQ);
            setDateDomiciliationLocauxCommun(state.dateDomiciliationLocauxCommun);
            setLocHabitPersoProprioQ(state.locHabitPersoProprioQ);
            setAssujettissementLoyerProComTvaQ(state.assujettissementLoyerProComTvaQ);
            setSurfaceExploitationSup1000mC(state.surfaceExploitationSup1000mC);
            setEmissionFactureSecteurPublic(state.emissionFactureSecteurPublic);
            setLocBiensImmoUsagePro(state.locBiensImmoUsagePro);
            setImpositionAssociesQ(state.impositionAssociesQ);
            setTraitementDonneesPerso(state.traitementDonneesPerso);
            setSousTraitantDonneesPersonnelles(state.sousTraitantDonneesPersonnelles);
            setCollectionDonnesPerso(state.collectionDonnesPerso);
            setTraitementListeAIPDoblig(state.traitementListeAIPDoblig);
            setCriteresDesignationDPO(state.criteresDesignationDPO);
            setEtabliRecevantPublicERP(state.etabliRecevantPublicERP);
            setProdDetenCollecteEliminDechetsQ(state.prodDetenCollecteEliminDechetsQ);
            setProdDetDechets5flux(state.prodDetDechets5flux);
            setCocontractantPrestationSup5k(state.cocontractantPrestationSup5k);
            setClassementLogementDPE(state.classementLogementDPE);
            setDetentionSiteInternetQ(state.detentionSiteInternetQ);
            setUtilisationTraceurCookies(state.utilisationTraceurCookies);
            setVersHonorairesComSup1200(state.versHonorairesComSup1200);
            setOperateurPlateforme(state.operateurPlateforme);
            setDatePremClotureX(state.datePremClotureX);
            setTotalBilanQ(state.totalBilanQ);
            setSiegeLocauxEnCommunX(state.siegeLocauxEnCommunX);
            setTotalCapitauxPropres(state.totalCapitauxPropres);
            setCapitalSocial(state.capitalSocial);
            setSocieteFilialeMereQ(state.societeFilialeMereQ);
            setTypeControlExerceSociete(state.typeControlExerceSociete);
            setDepasSeuilConsoCA48mBi24mEff250Q(state.depasSeuilConsoCA48mBi24mEff250Q);
            setInterventionConvReglSASSCASA(state.interventionConvReglSASSCASA);
            setInterventionConvReglSARL(state.interventionConvReglSARL);
            setNatureConvRegleIntevenues(state.natureConvRegleIntevenues);
            setVoitureVehiculeDeSocieteQ(state.voitureVehiculeDeSocieteQ);
            setVersementPayeType(state.versementPayeType);
            setTypeContratsTravail(state.typeContratsTravail);
            setExpositionTravailleurs(state.expositionTravailleurs);
            setNbSalarieExpositionPenibilite(state.nbSalarieExpositionPenibilite);
            setControleRepoasHebdomadaire(state.controleRepoasHebdomadaire);
            setInterventionEntrepriseExterieure(state.interventionEntrepriseExterieure);
            setCodeNaf(state.codeNaf);
            setNbCandtionMicroValide(state.nbCandtionMicroValide);
            setValidatedSteps(state.validatedSteps);
            setCatJuridiqueNiv1(state.catJuridiqueNiv1);
            setCatJuridiqueNiv2(state.catJuridiqueNiv2);
            setCatJuridiqueNiv3(state.catJuridiqueNiv3);
            setStepHistory(state.stepHistory);
        }
    };


    const moveToStep = (newStep) => {
        setStepHistory((prevHistory) => [...prevHistory, currentStep]);
        setCurrentStep(newStep);
        saveState();
    };

    const goBack = () => {
        setStepHistory((prevHistory) => {
            const newHistory = [...prevHistory];
            const lastStep = newHistory.pop();
            setCurrentStep(lastStep || 'activityInput');
            return newHistory;
        });
    };

    const handleActivityConfirm = () => {
        moveToStep('revenueSelection');
    };
    const handleInputChange = (e) => {
        setShowSpinner(e.target.value.length > 0);
        setInputValue(e.target.value);
        debouncedFetchSuggestions(e.target.value);
    };

    const fetchSuggestions = async (value) => {
        if (!value || value.length < 3) {
            setSuggestions([]);
            return;
        }
        const url = `https://recherche-entreprises.api.gouv.fr/search?q=${value}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            console.log(data);
            setSuggestions(data.results);
            setShowSpinner(false);
        } catch (error) {
            console.error('Erreur lors de la requête API:', error);
        }
    };

    const updateCompanyDetails = (data) => {
        setActivityDetails(data.activite_principale);
        setRaisonSociale(data.nom_complet || data.nom_raison_sociale);
        setSIRET(data.siege?.siret || '');
        setDateCreation(data.date_creation);
        setCatJuridiqueNiv1(data.nature_juridique.slice(0, 1));
        setCatJuridiqueNiv2(data.nature_juridique.slice(0, 2));
        setCatJuridiqueNiv3(data.nature_juridique.slice(0, 4));

        if (data.nature_juridique === '6540') {
            setIsSCI(true);
            setRegimeTva("FRANCHISE");
            setProprioBatimentImmoQ(['HABIT'])
            setLoyerProprioBailleur(true);
        } else if (data.nature_juridique[0] === '5') {
            setIsComanyCommercial(true);
            setProprioBatimentImmoQ(['HABIT'])
            setRegimeTva('RS');
        } else if (data.nature_juridique === '1000') {
            setIsMicroEntreprise(true);
            setRegimeTva("FRANCHISE");
        }
        setNAFsection(data.section_activite_principale);
        setCodeNaf(data.activite_principale);
        setNAFgroupe(data.activite_principale?.substring(0, 4) || '')
        moveToStep('activityConfirmation');
    };

    const handleClickedSuggestion = (suggestion) => {
        updateCompanyDetails(suggestion);
    }

    const handleSubmit = async () => {
        const url = `https://recherche-entreprises.api.gouv.fr/search?q=${inputValue}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            console.log(data)
            if (data.results && data.results.length === 1) {
                updateCompanyDetails(data.results[0]);
            }
        } catch (error) {
            console.error('Erreur lors de la requête API:', error);
        }
    };

    // Fonction pour défiler vers le haut de la page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // défilement fluide
        });
    };

    // Utilise useEffect pour appeler scrollToTop à chaque changement de question
    useEffect(() => {
        scrollToTop();  // Défiler vers le haut à chaque changement d'étape
    }, [currentStep]);


    // Utilisez useCallback pour mémoriser la version debounced de fetchSuggestions
    const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, 300), []);

    const [showLoginForm, setShowLoginForm] = useState(false);


    return (
        <div className="flex flex-col  pt-[72px] min-h-screen">
            <NavigationBar background={'gray-100'} isQuestionnaire={true}/>
         
            {currentStep === 'activityInput' && <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100">
                <h1 className="text-2xl font-bold text-[#1e4690] my-12">{t("siret_page.title1")}</h1>
                <div className="flex items-center justify-center px-4 w-full">
                    <input type="text" placeholder={t("siret_page.placeholder")} className="my-2 p-3 border border-white rounded-full w-full max-w-md"
                        value={inputValue} onChange={handleInputChange} />
                    {showSpinner && <Spinner color="info" aria-label="Info spinner example" className='ml-2' />}
                </div>

                <ul className={`list-none w-full max-w-md mb-2 overflow-y-auto ${suggestions.length > 6 && 'h-80'}`}>
                    {suggestions?.map((suggestion, index) => (
                        <li
                            key={`${index}-${suggestion.nom_raison_sociale}`}
                            className="p-2 bg-white cursor-pointer hover:bg-gray-100 px-4 border-l-8 border-[#112879] rounded-l-2xl"
                        >
                            <button className='text-sm text-[#112879] text-left' onClick={() => handleClickedSuggestion(suggestion)} >
                                <b>{suggestion.nom_raison_sociale || suggestion.nom_complet}</b> ({suggestion.matching_etablissements?.[0]?.siret || ''})
                                <p>{suggestion.siege?.adresse}</p>
                            </button>
                        </li>
                    ))}
                </ul>
                <a href="https://infogreffe.mesobligations.fr/fr/workspace"
                    rel="noreferrer" target="_blank" className="text-blue-600 hover:underline mb-6">
                    {t("siret_page.link")}
                </a>
                <button className="bg-[#1e4690] mt-6 text-white px-12 py-3 hover:bg-[#1e469094] transition rounded-full duration-300"
                    onClick={handleSubmit}>
                    {t("siret_page.button")}
                </button>
            </div>
            }
            {/* Q0 */}
            {currentStep === 'activityConfirmation' && activityDetails && (
                <ActivityConfirmation activity={activityDetails} onConfirm={handleActivityConfirm} nbStep={nbStep} />
            )}
            {/* Q1 */}
            {currentStep === 'revenueSelection' && (
                <RevenueRangeSelector
                    nbStep={nbStep}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} currentStep={currentStep}
                    isMicroEntreprise={isMicroEntreprise} isSCI={isSCI}
                    raisonSociale={raisonSociale}
                    chiffredAffairesTotalNet={chiffredAffairesTotalNet}
                    setChiffredAffairesTotalNet={setChiffredAffairesTotalNet}

                />
            )}
            {/* Q2 */}
            {currentStep === 'employeesSelection' && (
                <EmployeesRangeSelector
                    nbStep={nbStep}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} currentStep={currentStep}
                    raisonSociale={raisonSociale}
                    isMicroEntreprise={isMicroEntreprise} isSCI={isSCI}
                    setNbMoyenSalaries={setNbMoyenSalaries} />
            )}
            {/* Q3 */}
            {currentStep === 'regimeTva' && (
                <RegimeTva
                    nbStep={nbStep}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} currentStep={currentStep}
                    raisonSociale={raisonSociale}
                    isComanyCommercial={isComanyCommercial}
                    isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI}
                    setRegimeTva={setRegimeTva}
                    regimeTva={regimeTva}
                    catJuridiqueNiv3={catJuridiqueNiv3}
                />
            )}
            {/* Q4 if sci */}
            {currentStep === 'optionImpotSocieteISQ' && (
                <OptionImpotSocieteISQ
                    nbStep={nbStep}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    optionImpotSocieteISQ={optionImpotSocieteISQ}
                    moveToStep={moveToStep} goBack={goBack} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI} setOptionImpotSocieteISQ={setOptionImpotSocieteISQ} />
            )}
            {/* Q4 if micro */}
            {currentStep === 'optionRegimeMicroEntrepriseQ' && (
                <OptionRegimeMicroEntrepriseQ
                    nbStep={nbStep}
                    handleValidateStep={handleValidateStep}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    optionRegimeMicroEntrepriseQ={optionRegimeMicroEntrepriseQ}
                    moveToStep={moveToStep} goBack={goBack} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI} setOptionRegimeMicroEntrepriseQ={setOptionRegimeMicroEntrepriseQ} />
            )}
            {/* Q5 */}
            {currentStep === 'ProprioBatimentImmoQ' && (
                <ProprioBatimentImmoQ
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    NAFgroupe={NAFgroupe}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    proprioBatimentImmoQ={proprioBatimentImmoQ}
                    setProprioBatimentImmoQ={setProprioBatimentImmoQ}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q5 if commercial company */}
            {currentStep === 'SujecteToTva' && (
                <SujecteToTva
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    chiffredAffairesTotalNet={chiffredAffairesTotalNet}
                    auMoins90pCAsoumisTVA={auMoins90pCAsoumisTVA}
                    setAuMoins90pCAsoumisTVA={setAuMoins90pCAsoumisTVA}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q6 if sci*/}
            {currentStep === 'EquipmentSelector' && (
                <EquipmentSelector
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    proprioBatimentImmoQ={proprioBatimentImmoQ}
                    equipInstallationsSoumisVGPQ={equipInstallationsSoumisVGPQ}
                    setEquipInstallationsSoumisVGPQ={setEquipInstallationsSoumisVGPQ}
                    NAFsection={NAFsection}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q6 if micro*/}
            {currentStep === 'periodiciteDeclarationCA' && (
                <PeriodiciteDeclarationCA
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    handleValidateStep={handleValidateStep}
                    setNbCandtionMicroValide={setNbCandtionMicroValide}
                    periodiciteDeclarationCA={periodiciteDeclarationCA}
                    setPeriodiciteDeclarationCA={setPeriodiciteDeclarationCA}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q6 if commercial company */}
            {currentStep === 'TvaDeclareSup15k' && (
                <TvaDeclareSup15k
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    montantTVAdeclareSup15kQ={montantTVAdeclareSup15kQ}
                    setMontantTVAdeclareSup15kQ={setMontantTVAdeclareSup15kQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q7 */}
            {currentStep === 'LoyerProprioBailleur' && (
                <LoyerProprioBailleur
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    loyerProprioBailleur={loyerProprioBailleur}
                    setLoyerProprioBailleur={setLoyerProprioBailleur}
                    isSCI={isSCI} />
            )}
            {/* Q7 if micro*/}
            {currentStep === 'revenuFiscalReference' && (
                <RevenuFiscalReference
                    handleValidateStep={handleValidateStep}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    revenuFiscalReference={revenuFiscalReference}
                    setRevenuFiscalReference={setRevenuFiscalReference}
                    isSCI={isSCI} />
            )}
            {/* Q8 */}
            {currentStep === 'locMeubleeNonMeubleeQ' && (
                <LocMeubleeNonMeubleeQ
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    raisonSociale={raisonSociale}
                    isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI}
                    NAFgroupe={NAFgroupe}
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ}
                    setLocMeubleeNonMeubleeQ={setLocMeubleeNonMeubleeQ}
                    setDetentionSiteInternetQ={setDetentionSiteInternetQ}
                />
            )}
            {/* Q8 if micro*/}
            {currentStep === 'versementLiberatoireIR' && (
                <VersementLiberatoireIR
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    versementLiberatoireIR={versementLiberatoireIR}
                    setVersementLiberatoireIR={setVersementLiberatoireIR}
                    isSCI={isSCI} />
            )}

            {/* Q9 */}
            {currentStep === 'locMeubleeOccasMoins10p' && (
                <LocMeubleeOccasMoins10p
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    locMeubleeOccasMoins10p={locMeubleeOccasMoins10p}
                    setLocMeubleeOccasMoins10p={setLocMeubleeOccasMoins10p}
                    proprioBatimentImmoQ={proprioBatimentImmoQ}
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ}
                    NAFgroupe={NAFgroupe}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI} setDetentionSiteInternetQ={setDetentionSiteInternetQ}
                />

            )}
            {/* Q10 */}
            {currentStep === 'locationMeubleDeTourismeQ' && (
                <LocationMeubleDeTourismeQ
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ}
                    locationMeubleDeTourismeQ={locationMeubleDeTourismeQ}
                    setLocationMeubleDeTourismeQ={setLocationMeubleDeTourismeQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    NAFgroupe={NAFgroupe}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI} />
            )}
            {/* Q10 if micro*/}
            {currentStep === 'dateDomiciliationLocauxCommun' && (
                <DateDomiciliationLocauxCommun
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    dateDomiciliationLocauxCommun={dateDomiciliationLocauxCommun}
                    setDateDomiciliationLocauxCommun={setDateDomiciliationLocauxCommun}
                    isSCI={isSCI} />
            )}
            {/* Q11 */}
            {currentStep === 'locHabitPersoProprioQ' && (
                <LocHabitPersoProprioQ
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    raisonSociale={raisonSociale}
                    isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI}
                    locHabitPersoProprioQ={locHabitPersoProprioQ}
                    setLocHabitPersoProprioQ={setLocHabitPersoProprioQ}
                />
            )}
            {/* Q12 */}
            {currentStep === 'assujettissementLoyerProComTvaQ' && (
                <AssujettissementLoyerProComTvaQ
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ}
                    locMeubleeOccasMoins10p={locMeubleeOccasMoins10p}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    assujettissementLoyerProComTvaQ={assujettissementLoyerProComTvaQ}
                    setAssujettissementLoyerProComTvaQ={setAssujettissementLoyerProComTvaQ}
                    proprioBatimentImmoQ={proprioBatimentImmoQ}
                    isSCI={isSCI} />
            )}
            {/* Q13 */}
            {currentStep === 'surfaceExploitationSup1000mC' && (
                <SurfaceExploitationSup1000m2
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ}
                    locMeubleeOccasMoins10p={locMeubleeOccasMoins10p}
                    proprioBatimentImmoQ={proprioBatimentImmoQ}
                    surfaceExploitationSup1000mC={surfaceExploitationSup1000mC}
                    setSurfaceExploitationSup1000mC={setSurfaceExploitationSup1000mC}
                    NAFgroupe={NAFgroupe}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q13 if micro */}
            {currentStep === 'EmissionFactureSecteurPublic' && (
                <EmissionFactureSecteurPublic
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    isComanyCommercial={isComanyCommercial}
                    emissionFactureSecteurPublic={emissionFactureSecteurPublic}
                    setEmissionFactureSecteurPublic={setEmissionFactureSecteurPublic}

                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            { /* Q14 */}
            {currentStep === 'locBiensImmoUsagePro' && (
                <LocBiensImmoUsagePro
                    NAFgroupe={NAFgroupe}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    locBiensImmoUsagePro={locBiensImmoUsagePro}
                    setLocBiensImmoUsagePro={setLocBiensImmoUsagePro}
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ}
                    locMeubleeOccasMoins10p={locMeubleeOccasMoins10p}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI} setDetentionSiteInternetQ={setDetentionSiteInternetQ}
                />
            )}
            { /* Q15 */}
            {currentStep === 'impositionAssociesQ' && (
                <ImpositionAssociesQ
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    impositionAssociesQ={impositionAssociesQ}
                    setImpositionAssociesQ={setImpositionAssociesQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI} setDetentionSiteInternetQ={setDetentionSiteInternetQ}
                />

            )}
            { /* Q16 */}
            {currentStep === 'personnalDataSelection' && (
                <PersonnalDataSelector
                    nbStep={nbStep}
                    moveToStep={moveToStep} goBack={goBack} currentStep={currentStep}
                    raisonSociale={raisonSociale}
                    isMicroEntreprise={isMicroEntreprise} isSCI={isSCI}
                    SIRET={SIRET}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    isComanyCommercial={isComanyCommercial}
                    dateCreation={dateCreation}
                    traitementDonneesPerso={traitementDonneesPerso}
                    chiffredAffairesTotalNet={chiffredAffairesTotalNet}
                    nbMoyenSalaries={nbMoyenSalaries}
                    regimeTva={regimeTva}
                    NAFsection={NAFsection}
                    codeNaf={codeNaf}
                    setTraitementDonneesPerso={setTraitementDonneesPerso}
                />
            )}
            { /* Q17 */}
            {currentStep === 'DataSubContractor' && (
                <DataSubContractor
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    sousTraitantDonneesPersonnelles={sousTraitantDonneesPersonnelles}
                    setSousTraitantDonneesPersonnelles={setSousTraitantDonneesPersonnelles}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q18 */}
            {currentStep === 'collectionDonnesPerso' && (
                <CollectionDonnesPerso
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    setCollectionDonnesPerso={setCollectionDonnesPerso}
                    collectionDonnesPerso={collectionDonnesPerso}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q19 */}
            {currentStep === 'traitementListeAIPDoblig' && (
                <TraitementListeAIPDoblig
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    traitementListeAIPDoblig={traitementListeAIPDoblig}
                    setTraitementListeAIPDoblig={setTraitementListeAIPDoblig}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q20 */}
            {currentStep === 'criteresDesignationDPO' && (
                <CriteresDesignationDPO
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    isComanyCommercial={isComanyCommercial}
                    criteresDesignationDPO={criteresDesignationDPO}
                    setCriteresDesignationDPO={setCriteresDesignationDPO}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q21 */}
            {currentStep === 'eprSelection' && (
                <EprSelector
                    nbStep={nbStep}
                    isComanyCommercial={isComanyCommercial}
                    moveToStep={moveToStep} goBack={goBack} currentStep={currentStep}
                    raisonSociale={raisonSociale}
                    isMicroEntreprise={isMicroEntreprise} isSCI={isSCI}
                    setEtabliRecevantPublicERP={setEtabliRecevantPublicERP}
                    etabliRecevantPublicERP={etabliRecevantPublicERP}
                />
            )}
            {/* Q21 if commercial */}
            {currentStep === 'prodDetenCollecteEliminDechetsQ' && (
                <ProdDetenCollecteEliminDechetsQ
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep}
                    NAFgroupe={NAFgroupe}
                    prodDetenCollecteEliminDechetsQ={prodDetenCollecteEliminDechetsQ}
                    setProdDetenCollecteEliminDechetsQ={setProdDetenCollecteEliminDechetsQ}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q22 */}
            {currentStep === 'prodDetDechets5flux' && (
                <ProdDetDechets5flux
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    prodDetDechets5flux={prodDetDechets5flux}
                    isComanyCommercial={isComanyCommercial}
                    setProdDetDechets5flux={setProdDetDechets5flux}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q23 */}

            {currentStep === 'ContractantPrestationSup' && (
                <ContractantPrestationSup
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    cocontractantPrestationSup5k={cocontractantPrestationSup5k}
                    setCocontractantPrestationSup5k={setCocontractantPrestationSup5k}
                    proprioBatimentImmoQ={proprioBatimentImmoQ}
                    isComanyCommercial={isComanyCommercial}
                    setProprioBatimentImmoQ={setProprioBatimentImmoQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}


            {/* Q24 */}


            {currentStep === 'ClassementLogementDPE' && (
                <ClassementLogementDPE
                    classementLogementDPE={classementLogementDPE}
                    setClassementLogementDPE={setClassementLogementDPE}
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ}
                    locMeubleeOccasMoins10p={locMeubleeOccasMoins10p}
                    proprioBatimentImmoQ={proprioBatimentImmoQ}
                    NAFgroupe={NAFgroupe}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    NAFsection={NAFsection}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q25 */}
            {currentStep === 'DetentionSiteInternet' && (
                <DetentionSiteInternet
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    isComanyCommercial={isComanyCommercial}
                    setDetentionSiteInternetQ={setDetentionSiteInternetQ}
                    detentionSiteInternetQ={detentionSiteInternetQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep}
                    raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI}


                    SIRET={SIRET}
                    codeNaf={codeNaf}
                    NAFsection={NAFsection}
                    catJuridiqueNiv1={catJuridiqueNiv1}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    catJuridiqueNiv3={catJuridiqueNiv3}

                    chiffredAffairesTotalNetX={chiffredAffairesTotalNet}
                    nbMoyenSalaries={nbMoyenSalaries}
                    regimeTVANormalSimplifieFranQ={regimeTva}
                    optionRegimeMicroEntrepriseQ={optionRegimeMicroEntrepriseQ}
                    optionImpotSocieteISQ={optionImpotSocieteISQ}

                    periodiciteDeclarationCA={periodiciteDeclarationCA.length === 0 ? null : periodiciteDeclarationCA}
                    revenuFiscalReference={revenuFiscalReference}
                    versementLiberatoireIR={versementLiberatoireIR}

                    SiegeLocauxEnCommunX={siegeLocauxEnCommunX}
                    dateDomiciliationLocauxCommun={dateDomiciliationLocauxCommun}

                    cocontractantPrestationSup5k={cocontractantPrestationSup5k}
                    versHonorairesComSup1200={versHonorairesComSup1200}
                    emissionFactureSecteurPublicQ={emissionFactureSecteurPublic}
                    utilisationTraceurCookies={utilisationTraceurCookies}

                />
            )}
            {/* Q26 */}
            {currentStep === 'UsedTraceurCookies' && (
                <UsedTraceurCookies
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    utilisationTraceurCookies={utilisationTraceurCookies}
                    setUtilisationTraceurCookies={setUtilisationTraceurCookies}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q26 if commercial */}
            {currentStep === 'versHonorairesComSup1200' && (
                <VersHonorairesComSup1200
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    versHonorairesComSup1200={versHonorairesComSup1200}
                    setVersHonorairesComSup1200={setVersHonorairesComSup1200}
                    proprioBatimentImmoQ={proprioBatimentImmoQ}
                    isComanyCommercial={isComanyCommercial}
                    setProprioBatimentImmoQ={setProprioBatimentImmoQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q27 */}

            {currentStep === 'operateurPlateforme' && (
                <OperateurPlateforme
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    operateurPlateforme={operateurPlateforme}
                    setOperateurPlateforme={setOperateurPlateforme}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise}
                    isSCI={isSCI} setDetentionSiteInternetQ={setDetentionSiteInternetQ}

                    SIRET={SIRET}
                    codeNaf={codeNaf}
                    NAFsection={NAFsection}
                    catJuridiqueNiv1={catJuridiqueNiv1}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    catJuridiqueNiv3={catJuridiqueNiv3}

                    chiffredAffairesTotalNetX={chiffredAffairesTotalNet}
                    nbMoyenSalaries={nbMoyenSalaries}
                    regimeTVANormalSimplifieFranQ={regimeTva}
                    optionRegimeMicroEntrepriseQ={optionRegimeMicroEntrepriseQ}
                    optionImpotSocieteISQ={optionImpotSocieteISQ}

                    periodiciteDeclarationCA={periodiciteDeclarationCA.length === 0 ? null : periodiciteDeclarationCA}
                    revenuFiscalReference={revenuFiscalReference}
                    versementLiberatoireIR={versementLiberatoireIR}

                    SiegeLocauxEnCommunX={siegeLocauxEnCommunX}
                    dateDomiciliationLocauxCommun={dateDomiciliationLocauxCommun}

                    cocontractantPrestationSup5k={cocontractantPrestationSup5k}
                    versHonorairesComSup1200={versHonorairesComSup1200}
                    emissionFactureSecteurPublicQ={emissionFactureSecteurPublic}
                    detentionSiteInternetQ={detentionSiteInternetQ}
                    utilisationTraceurCookies={utilisationTraceurCookies}

                />
            )}
            {/* Q28 */}

            {currentStep === 'dateClosed' && (
                <DateClosed
                    datePremClotureX={datePremClotureX}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    setDatePremClotureX={setDatePremClotureX}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}

            {/* Q29 */}
            {currentStep === 'totalBilanQ' && (
                <TotalBilan
                    isComanyCommercial={isComanyCommercial}
                    totalBilanQ={totalBilanQ}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    setTotalBilanQ={setTotalBilanQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}

            {/* Q30 : Send to API if SCI*/}
            {currentStep === 'SiegeLocauxEnCommunX' && (
                <SiegeLocauxEnCommunX
                    setSiegeLocauxEnCommunX={setSiegeLocauxEnCommunX}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack}
                    nbStep={nbStep} currentStep={currentStep}
                    raisonSociale={raisonSociale}
                    isMicroEntreprise={isMicroEntreprise} isSCI={isSCI}
                    isComanyCommercial={isComanyCommercial}
                    SIRET={SIRET}
                    codeNaf={codeNaf}
                    NAFsection={NAFsection}
                    catJuridiqueNiv1={catJuridiqueNiv1}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    catJuridiqueNiv3={catJuridiqueNiv3}

                    chiffredAffairesTotalNet={chiffredAffairesTotalNet} //1
                    nbMoyenSalaries={nbMoyenSalaries} //2
                    regimeTva={regimeTva} // 3
                    optionImpotSocieteISQ={optionImpotSocieteISQ} //4
                    proprioBatimentImmoQ={proprioBatimentImmoQ.length === 0 ? null : proprioBatimentImmoQ} // 5
                    equipInstallationsSoumisVGPQ={equipInstallationsSoumisVGPQ.length === 0 ? null : equipInstallationsSoumisVGPQ} // 6
                    loyerProprioBailleur={loyerProprioBailleur} // 7
                    locMeubleeNonMeubleeQ={locMeubleeNonMeubleeQ.length === 0 ? null : locMeubleeNonMeubleeQ} // 8
                    locMeubleeOccasMoins10p={locMeubleeOccasMoins10p} // 9
                    locationMeubleDeTourismeQ={locationMeubleDeTourismeQ} // 10
                    locHabitPersoProprioQ={locHabitPersoProprioQ} //11
                    assujettissementLoyerProComTvaQ={assujettissementLoyerProComTvaQ} // 12
                    surfaceExploitationSup1000mC={surfaceExploitationSup1000mC} //13
                    locBiensImmoUsagePro={locBiensImmoUsagePro.length === 0 ? null : locBiensImmoUsagePro} //14
                    impositionAssociesQ={impositionAssociesQ.length === 0 ? null : impositionAssociesQ} //15
                    traitementDonneesPerso={traitementDonneesPerso}//16
                    sousTraitantDonneesPersonnelles={sousTraitantDonneesPersonnelles}//17
                    collectionDonnesPerso={collectionDonnesPerso}//18
                    traitementListeAIPDoblig={traitementListeAIPDoblig}//19
                    criteresDesignationDPO={criteresDesignationDPO}//20
                    etabliRecevantPublicERP={etabliRecevantPublicERP}//21
                    prodDetDechets5flux={prodDetDechets5flux}//22
                    cocontractantPrestationSup5k={cocontractantPrestationSup5k}//23
                    classementLogementDPE={classementLogementDPE.length === 0 ? null : classementLogementDPE} //24
                    detentionSiteInternetQ={detentionSiteInternetQ} //25
                    utilisationTraceurCookies={utilisationTraceurCookies} // 26
                    operateurPlateforme={operateurPlateforme.length === 0 ? null : operateurPlateforme} //27
                    datePremClotureX={datePremClotureX} //28
                    totalBilanQ={totalBilanQ} // 29
                    siegeLocauxEnCommunX={siegeLocauxEnCommunX} //30
                // dateCreation={dateCreation}

                />
            )}

            {/* Q33 if commercial */}

            {currentStep === "typeControlExerceSociete" && (
                <TypeControlExerceSociete
                    typeControlExerceSociete={typeControlExerceSociete}
                    setTypeControlExerceSociete={setTypeControlExerceSociete}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    catJuridiqueNiv1={catJuridiqueNiv1}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    nbMoyenSalaries={nbMoyenSalaries}
                    chiffredAffairesTotalNet={chiffredAffairesTotalNet}
                    totalBilanQ={totalBilanQ}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />


            )}

            {currentStep === "depasSeuilConsoCA48mBi24mEff250Q" && (
                <DepasSeuilConsoCA48mBi24mEff250Q
                    depasSeuilConsoCA48mBi24mEff250Q={depasSeuilConsoCA48mBi24mEff250Q}
                    setDepasSeuilConsoCA48mBi24mEff250Q={setDepasSeuilConsoCA48mBi24mEff250Q}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack}
                    nbStep={nbStep} currentStep={currentStep}
                    raisonSociale={raisonSociale}
                    isMicroEntreprise={isMicroEntreprise} isSCI={isSCI}
                    codeNaf={codeNaf}
                    SIRET={SIRET}
                    isComanyCommercial={isComanyCommercial}
                    catJuridiqueNiv1={catJuridiqueNiv1}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                />
            )}






            {/* a retirer si SCI q15*/}
            {currentStep === 'ProdDetDechets' && (
                <ProdDetDechets
                    societeFilialeMereQ={societeFilialeMereQ}
                    setSocieteFilialeMereQ={setSocieteFilialeMereQ}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    catJuridiqueNiv1={catJuridiqueNiv1}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    nbMoyenSalaries={nbMoyenSalaries}
                    chiffredAffairesTotalNet={chiffredAffairesTotalNet}
                    totalBilanQ={totalBilanQ}

                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}




            {/* a retirer si SCI q22 */}
            {currentStep === 'totalCapitauxPropres' && (
                <TotalCapitauxPropres
                    totalCapitauxPropres={totalCapitauxPropres}
                    setTotalCapitauxPropres={setTotalCapitauxPropres}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* a retirer si SCI q23 */}
            {currentStep === 'CapitalSocial' && (
                <CapitalSocial
                    capitalSocial={capitalSocial}
                    setCapitalSocial={setCapitalSocial}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}


            {/* a retirer si SCI q26 */}

            {currentStep === 'interventionConvReglSASSCASA' && (
                <InterventionConvReglSASSCASA
                    interventionConvReglSASSCASA={interventionConvReglSASSCASA}
                    setInterventionConvReglSASSCASA={setInterventionConvReglSASSCASA}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}

            {currentStep === 'interventionConvReglSARL' && (
                <InterventionConvReglSARL
                    interventionConvReglSARL={interventionConvReglSARL}
                    interventionConvReglSASSCASA={interventionConvReglSASSCASA}
                    setInterventionConvReglSARL={setInterventionConvReglSARL}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />

            )}

            {currentStep === 'natureConvRegleIntevenues' && (
                <NatureConvRegleIntevenues
                    natureConvRegleIntevenues={natureConvRegleIntevenues}
                    setNatureConvRegleIntevenues={setNatureConvRegleIntevenues}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />

            )}

            {/* Q38 */}
            {currentStep === 'VehiculeDeSociete' && (
                <VehiculeDeSociete
                    voitureVehiculeDeSocieteQ={voitureVehiculeDeSocieteQ}
                    setVoitureVehiculeDeSocieteQ={setVoitureVehiculeDeSocieteQ}
                    nbMoyenSalaries={nbMoyenSalaries}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI}
                    isComanyCommercial={isComanyCommercial}
                    SIRET={SIRET}
                    codeNaf={codeNaf}
                    NAFsection={NAFsection}
                    catJuridiqueNiv1={catJuridiqueNiv1}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    catJuridiqueNiv3={catJuridiqueNiv3}

                    chiffredAffairesTotalNetX={chiffredAffairesTotalNet}
                    regimeTVANormalSimplifieFranQ={regimeTva}
                    optionImpotSocieteISQ={optionImpotSocieteISQ}
                    auMoins90pCAsoumisTVA={auMoins90pCAsoumisTVA}
                    montantTVAdeclareSup15kQ={montantTVAdeclareSup15kQ}
                    proprioBatimentImmoQ={proprioBatimentImmoQ.length === 0 ? null : proprioBatimentImmoQ}
                    loyerProprioBailleur={loyerProprioBailleur}
                    equipInstallationsSoumisVGPQ={equipInstallationsSoumisVGPQ.length === 0 ? null : equipInstallationsSoumisVGPQ}
                    classementLogementDPE={classementLogementDPE.length === 0 ? null : classementLogementDPE}
                    surfaceExploitationSup1000mC={surfaceExploitationSup1000mC}
                    traitementDonneesPerso={traitementDonneesPerso}
                    sousTraitantDonneesPersonnelles={sousTraitantDonneesPersonnelles}
                    collectionDonnesPerso={collectionDonnesPerso}
                    traitementListeAIPDoblig={traitementListeAIPDoblig}
                    criteresDesignationDPO={criteresDesignationDPO}
                    detentionSiteInternetQ={detentionSiteInternetQ}
                    utilisationTraceurCookies={utilisationTraceurCookies}
                    operateurPlateforme={operateurPlateforme.length === 0 ? null : operateurPlateforme}
                    prodDetDechets5flux={prodDetDechets5flux}
                    prodDetenCollecteEliminDechetsQ={prodDetenCollecteEliminDechetsQ.length === 0 ? null : prodDetenCollecteEliminDechetsQ}
                    etabliRecevantPublicERP={etabliRecevantPublicERP}
                    SiegeLocauxEnCommunX={siegeLocauxEnCommunX}
                    dateDomiciliationLocauxCommun={dateDomiciliationLocauxCommun}
                    cocontractantPrestationSup5k={cocontractantPrestationSup5k}
                    versHonorairesComSup1200={versHonorairesComSup1200}
                    emissionFactureSecteurPublic={emissionFactureSecteurPublic}
                    datePremClotureX={datePremClotureX}
                    totalBilanQ={totalBilanQ}
                    totalCapitauxPropres={totalCapitauxPropres}
                    capitalSocial={capitalSocial}
                    societeFilialeMereQ={societeFilialeMereQ.length === 0 ? null : societeFilialeMereQ}
                    typeControlExerceSociete={typeControlExerceSociete.length === 0 ? null : typeControlExerceSociete}
                    depasSeuilConsoCA48mBi24mEff250Q={depasSeuilConsoCA48mBi24mEff250Q}
                    interventionConvReglSASSCASA={interventionConvReglSASSCASA}
                    interventionConvReglSARL={interventionConvReglSARL}
                    natureConvRegleIntevenues={natureConvRegleIntevenues}
                />
            )}
            {/* Q39 */}
            {currentStep === 'VersementPayeType' && (
                <VersementPayeType
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    versementPayeType={versementPayeType}
                    setVersementPayeType={setVersementPayeType}

                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}

            {/* Q40 */}

            {currentStep === 'TypeContratsTravail' && (
                <TypeContratsTravail
                    typeContratsTravail={typeContratsTravail}
                    setTypeContratsTravail={setTypeContratsTravail}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}

            {/* Q41 */}
            {currentStep === 'expositionTravailleurs' && (
                <ExpositionTravailleurs
                    expositionTravailleurs={expositionTravailleurs}
                    setExpositionTravailleurs={setExpositionTravailleurs}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}

            {/* Q42 */}

            {currentStep === 'nbSalarieExpositionPenibilite' && (
                <NbSalarieExpositionPenibilite
                    nbSalarieExpositionPenibilite={nbSalarieExpositionPenibilite}
                    setNbSalarieExpositionPenibilite={setNbSalarieExpositionPenibilite}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}
            {/* Q43 */}
            {currentStep === 'controleRepoasHebdomadaire' && (
                <ControleRepoasHebdomadaire
                    controleRepoasHebdomadaire={controleRepoasHebdomadaire}
                    setControleRepoasHebdomadaire={setControleRepoasHebdomadaire}
                    nbMoyenSalaries={nbMoyenSalaries}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI} />
            )}

            {/* Q44 */}
            {currentStep === 'interventionEntrepriseExterieure' && (
                <InterventionEntrepriseExterieure
                    interventionEntrepriseExterieure={interventionEntrepriseExterieure}
                    setInterventionEntrepriseExterieure={setInterventionEntrepriseExterieure}
                    nbCandtionMicroValide={nbCandtionMicroValide}
                    moveToStep={moveToStep} goBack={goBack} nbStep={nbStep} currentStep={currentStep} raisonSociale={raisonSociale} isMicroEntreprise={isMicroEntreprise} isSCI={isSCI}

                    isComanyCommercial={isComanyCommercial}
                    SIRET={SIRET}
                    codeNaf={codeNaf}
                    NAFsection={NAFsection}
                    catJuridiqueNiv1={catJuridiqueNiv1}
                    catJuridiqueNiv2={catJuridiqueNiv2}
                    catJuridiqueNiv3={catJuridiqueNiv3}

                    chiffredAffairesTotalNetX={chiffredAffairesTotalNet}
                    nbMoyenSalaries={nbMoyenSalaries}
                    regimeTVANormalSimplifieFranQ={regimeTva}
                    optionImpotSocieteISQ={optionImpotSocieteISQ}
                    auMoins90pCAsoumisTVA={auMoins90pCAsoumisTVA}
                    montantTVAdeclareSup15kQ={montantTVAdeclareSup15kQ}
                    proprioBatimentImmoQ={proprioBatimentImmoQ.length === 0 ? null : proprioBatimentImmoQ}
                    loyerProprioBailleur={loyerProprioBailleur}
                    equipInstallationsSoumisVGPQ={equipInstallationsSoumisVGPQ.length === 0 ? null : equipInstallationsSoumisVGPQ}
                    classementLogementDPE={classementLogementDPE.length === 0 ? null : classementLogementDPE}
                    surfaceExploitationSup1000mC={surfaceExploitationSup1000mC}
                    traitementDonneesPerso={traitementDonneesPerso}
                    sousTraitantDonneesPersonnelles={sousTraitantDonneesPersonnelles}
                    collectionDonnesPerso={collectionDonnesPerso}
                    traitementListeAIPDoblig={traitementListeAIPDoblig}
                    criteresDesignationDPO={criteresDesignationDPO}
                    detentionSiteInternetQ={detentionSiteInternetQ}
                    utilisationTraceurCookies={utilisationTraceurCookies}
                    operateurPlateforme={operateurPlateforme.length === 0 ? null : operateurPlateforme}
                    prodDetDechets5flux={prodDetDechets5flux}
                    prodDetenCollecteEliminDechetsQ={prodDetenCollecteEliminDechetsQ.length === 0 ? null : prodDetenCollecteEliminDechetsQ}
                    etabliRecevantPublicERP={etabliRecevantPublicERP}
                    SiegeLocauxEnCommunX={siegeLocauxEnCommunX}
                    dateDomiciliationLocauxCommun={dateDomiciliationLocauxCommun}
                    cocontractantPrestationSup5k={cocontractantPrestationSup5k}
                    versHonorairesComSup1200={versHonorairesComSup1200}
                    emissionFactureSecteurPublic={emissionFactureSecteurPublic}
                    datePremClotureX={datePremClotureX}
                    totalBilanQ={totalBilanQ}
                    totalCapitauxPropres={totalCapitauxPropres}
                    capitalSocial={capitalSocial}
                    societeFilialeMereQ={societeFilialeMereQ.length === 0 ? null : societeFilialeMereQ}
                    typeControlExerceSociete={typeControlExerceSociete.length === 0 ? null : typeControlExerceSociete}
                    depasSeuilConsoCA48mBi24mEff250Q={depasSeuilConsoCA48mBi24mEff250Q}
                    interventionConvReglSASSCASA={interventionConvReglSASSCASA}
                    interventionConvReglSARL={interventionConvReglSARL}
                    natureConvRegleIntevenues={natureConvRegleIntevenues}
                    voitureVehiculeDeSocieteQ={voitureVehiculeDeSocieteQ}
                    versementPayeType={versementPayeType}
                    typeContratsTravail={typeContratsTravail.length === 0 ? null : typeContratsTravail}
                    expositionTravailleurs={expositionTravailleurs.length === 0 ? null : expositionTravailleurs}
                    nbSalarieExpositionPenibilite={nbSalarieExpositionPenibilite}
                    controleRepoasHebdomadaire={controleRepoasHebdomadaire}
                />
            )}

        </div>
    );
};

export default SiretPage;
