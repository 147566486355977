
import MainRecapWorkspace from "../components/recapWorkspace/MainRecapWorkspace";
import SideBar from "../components/recapWorkspace/SideBar";
import React, { useEffect, useRef, useState } from 'react';
import DisclaimerModal from "../components/recapWorkspace/DisclaimerModal";
import { BiLogoTelegram } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { getEnvConfig } from "../utils/config";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

function RecapWorkspace() {

    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [sessionId, setSessionId] = useState(false);
    const [result, setResult] = useState(() => {
        const savedResult = localStorage.getItem("result");
        return savedResult ? JSON.parse(savedResult) : {};
    });
    const [raisonSociale, setRaisonSociale] = useState(() => {
        const savedRaisonSociale = localStorage.getItem("raisonSociale");
        return savedRaisonSociale || '';
    });
    const location = useLocation();
    const { currentUser, userData } = useAppContext();

    console.log("userData RecapWorkspace :", userData)

    useEffect(() => {
        // Si result ou raisonSociale sont manquants, les charger depuis localStorage
        if (!result || Object.keys(result).length === 0) {
            const savedResult = localStorage.getItem("result");
            if (savedResult) {
                setResult(JSON.parse(savedResult));
            }
        }
        if (!raisonSociale) {
            const savedRaisonSociale = localStorage.getItem("raisonSociale");
            if (savedRaisonSociale) {
                setRaisonSociale(savedRaisonSociale);
            }
        }
    }, []);


    useEffect(() => {
        // Vérifier si session_id est présent dans l'URL
        const queryParams = new URLSearchParams(location.search);
        const sessionId = queryParams.get('session_id');
        if (sessionId) {
            setShowModal(true);
            setSessionId(sessionId)
        }
    }, [location]);

    console.log("result RecapWorkspace : ", result)

    const organizeData = (data) => {
        const thematicMap = new Map();

        // Organiser les données par thématique et compter les détails
        data.forEach(item => {
            const theme = item.Topic;
            const detail = item.Topic2;
            const title = item.Title;

            // Vérifie si la thématique existe déjà
            if (!thematicMap.has(theme)) {
                thematicMap.set(theme, { title: theme, details: new Map() });
            }

            const themeDetails = thematicMap.get(theme).details;

            // Compter les occurrences des détails
            if (!themeDetails.has(detail)) {
                themeDetails.set(detail, { name: detail, count: 1 });
            } else {
                themeDetails.get(detail).count += 1;
            }
        });
        // Transformer les Maps en array d'objets pour une utilisation plus simple en React
        const organizedData = Array.from(thematicMap, ([title, { details }]) => ({
            title,
            details: Array.from(details, ([name, detailData]) => detailData)
        }));
        console.log("organizedData: ", organizedData)

        return organizedData;
    };

    const toggleSidebar = () => setIsOpen(!isOpen);

    const ModalValidationPayement = ({ onClose, sessionId }) => {
        const modalRef = useRef();
        const [isLoading, setIsLoading] = useState(false)

        const handleOverlayClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClose();
            }
        };

        const downloadReport = async () => {
            setIsLoading(true);
            const { apiUrl } = getEnvConfig();
            try {
                const response = await fetch(`${apiUrl}download-report/${sessionId}`, {
                    method: "GET",
                });
                if (response.ok) {
                    const data = await response.json();
                    const { filename, fileContent } = data;

                    // Décoder le contenu base64
                    const binaryString = atob(fileContent);
                    const bytes = new Uint8Array(binaryString.length);
                    for (let i = 0; i < binaryString.length; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }

                    const blob = new Blob([bytes], { type: 'application/pdf' });
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.setAttribute("download", filename || 'rapport_conformite.pdf');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setShowModal(false);
                } else {
                    throw new Error('Erreur lors du téléchargement du rapport');
                }
            } catch (error) {
                console.error('Erreur lors du téléchargement:', error.message);
                alert("Impossible de télécharger le rapport. Veuillez réessayer.");
            } finally {
                setIsLoading(false);
            }
        };

        return (
            <div
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
                onClick={handleOverlayClick}
            >
                <div
                    className="relative bg-white rounded-3xl text-[#006acc] shadow-lg w-full max-w-md mx-auto p-6"
                    ref={modalRef}
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-2xl font-bold"
                    >
                        &times;
                    </button>
                    <div className="flex flex-col items-center">
                        <h2 className="text-2xl text-center font-bold mb-4">
                            Merci pour votre commande
                        </h2>
                        <BiLogoTelegram size={60} className="mb-4 text-[#006acc]" />
                        <h2 className="text-2xl text-center font-bold mb-4">
                            Votre rapport a bien été envoyé.
                        </h2>
                        <p className="text-center">(vérifiez vos spams)</p>
                        <p className="text-center">ou</p>
                        <p className="text-center mb-4">Vous pouvez egalement</p>
                        <button
                            className="bg-white font-bold text-[#006acc] border border-[#006acc] px-16 py-2 hover:scale-105 rounded-full"
                            onClick={downloadReport}
                        >
                            {isLoading ? (
                                <>
                                    <span className="flex justify-between items-center">
                                        Chargement...
                                        <AiOutlineLoading3Quarters className="animate-spin m-2" />
                                    </span>
                                </>
                            ) : (
                                'Telecharger le rapport'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
            <SideBar nbObligationsApplicables={result.Results[0].Data.length} nbObligationspotentiels={result.Results[0].Potentials}
                raisonSociale={raisonSociale} isMobile={isMobile} isOpen={isOpen} setIsMobile={setIsMobile} setIsOpen={setIsOpen} toggleSidebar={toggleSidebar} />

            <div className="flex flex-col flex-1 overflow-hidden bg-[#E5F0FF]">

                <MainRecapWorkspace nbObligations={result.Results[0].Data.length} thematiques={organizeData(result.Results[0].Data)} result={result}
                    isMobile={isMobile} isOpen={isOpen} setIsMobile={setIsMobile} setIsOpen={setIsOpen} raisonSociale={raisonSociale}
                    toggleSidebar={toggleSidebar} currentUser={currentUser} userData={userData} />

                <DisclaimerModal
                    isOpen={isDisclaimerOpen}
                    onClose={() => setIsDisclaimerOpen(false)}
                />

                {showModal && (
                    <ModalValidationPayement onClose={() => setShowModal(false)} sessionId={sessionId} />
                )}
            </div>
        </div>
    );
}

export default RecapWorkspace;