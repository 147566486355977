import React from "react";
import NavigationBar from "../components/NavigationBar";
import Footer from "./Footer";

const PolitiqueConfidentialite = () => {
    return (
        <div className="flex flex-col bg-white pt-[72px]">
            <NavigationBar />
            <div className="relative flex-grow flex flex-col text-start min-h-96">
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-3xl text-[#1244a0] font-bold mb-6">Politique de confidentialité applicable aux Utilisateurs</h1>

                    <div className="col-md-12 text-[#919191] text-xl" id="texte">
                        <h2 className="text-2xl font-semibold mt-6 mb-4">PRÉAMBULE:</h2>
                        <p>Les présentes ont vocation à informer l’Utilisateur (tel que ce terme est défini ci-après) et, plus globalement toute personne concernée :</p>
                        <ul className="list-disc pl-5 mb-4">
                            <li>(i) sur la manière dont la société Legaware (société par actions simplifiée au capital de 1.084 €, dont le siège social est sis 4 Rue des Saussaies, 75008 Paris, immatriculée au registre du commerce et des sociétés de Paris sous le numéro 820 434 587) (ci-après « Legaware ») traite les données à caractère personnel communiquées par l’Utilisateur dans le cadre de l’utilisation par l’Utilisateur du site accessible à l’adresse https://infogreffe.mesobligations.fr (ci-après le « Site ») et/ou de la commande et/ou l’usage par l’Utilisateur des Services (tel que ce terme est défini ci-après) ;</li>
                            <li>(ii) sur la manière dont les Professionnels Référencés (tel que ce terme est défini ci-après) peuvent se voir remettre certaines de ces données à caractère personnel et sur la manière dont sont alors traitées ces données à caractère personnel ;</li>
                        </ul>

                        <p className="mb-4">Le GIE Infogreffe délègue la gestion du Site à la société partenaire Legaware, laquelle exploite le Site et délivre, via le Site, des Services (tels que ce terme est défini ci-après) lesquels comprennent (i) un service d’identification des droits et des obligations légales et réglementaires (« LegalScan ») qui est exécuté par Legaware (ii) et un service permettant à un Utilisateur de poser, via le Site, des questions à des Professionnels Référencés, les réponses à ces questions étant alors apportées par les Professionnels Référencés et non par Legaware (iii) ainsi qu’un service de mise en relation avec un Professionnel Référencé dans le cadre d’un accompagnement dans la mise en conformité par un Professionnel Référencé, ce service permettant ainsi à l’Utilisateur de confier à un Professionnel Référencé l’exécution d’une prestation d’accompagnement dans la mise en conformité, cette prestation étant alors exécutée par le Professionnel Référencé et non par Legaware.</p>
                        <p className="mb-4">La réponse aux questions posées par les Utilisateurs aux Professionnels Référencés et la réalisation par un Professionnel Référencé des prestations lui étant confiées par un Utilisateur impliquant que Legaware confère au Professionnel Référencé un accès à certaines données à caractère personnel concernant l’Utilisateur (via un accès à son système informatique), c’est la raison pour laquelle le Professionnel Référencé ainsi que son rôle quant au traitement de ces données à caractère personnel (dans le cas où l’Utilisateur pose des questions ou confie une prestation à un Professionnel Référencé) sont identifiés aux présentes.</p>

                        <p className="mb-4">La présente politique de confidentialité (ci-après la « Politique de Confidentialité Utilisateurs) a pour objet d'identifier la description des droits de l'Utilisateur et plus globalement de toutes personnes concernées au titre des dispositions de la loi n°78-17 du 6 janvier 1978 et du règlement UE 2016/679 du 27 avril 2016 (RGPD) et des autres dispositions légales en vigueur (ci-après la « Réglementation en vigueur ») et la protection qui en résulte.</p>
                        <p className="mb-4">Dans ce cadre, la Politique de Confidentialité Utilisateurs (i) fait l’objet d’une acceptation par l’Utilisateur dans le cadre de la commande par ses soins des Services et (ii) est également diffusée sur le Site.</p>
                        <p className="mb-4">Il est à ce titre porté à la connaissance de l’Utilisateur et, plus globalement à la connaissance de toutes personnes concernées, que la présente Politique de Confidentialité Utilisateurs (i) s’applique aux données à caractère personnel communiquées par l’Utilisateur à Legaware dans le cadre de la collecte de données à caractère personnel identifiée ci-après, c’est-à-dire dans le cadre du « Traitement de Données Personnelles Legaware » (tel que ce terme est défini ci-après au (a) de l’article « Les données à caractère personnel objets d’une collecte ») et (ii) s’applique également aux données à caractère personnel concernant lesquelles Legaware confère un accès aux Professionnels Référencés quand un Utilisateur pose une question ou confie une Prestation à un Professionnel Référéncé, c’est-à-dire dans le cadre du « Traitement de Données Personnelles Accessibles aux Professionnels Référencés » (tel que ce terme est défini ci-après au (b) de l’article « Les données à caractère personnel objets d’une collecte ») (iii) la présente Politique de Confidentialité Utilisateurs ne s’appliquant pas aux données à caractère personnel qui seraient directement transmises par l’Utilisateur au Professionnel Référencé en dehors du cadre du Traitement de Données Personnelles Accessibles aux Professionnels Référencés, notamment dans le cadre de la relation contractuelle directe que noue l’Utilisateur avec tout Professionnel Référencé de sorte qu’en pareille hypothèse et concernant une telle transmission de telles données, seule la politique de confidentialité qui aurait été mise en place par le Professionnel Référencé trouverait à s’appliquer.</p>
                        <p className="mb-4">Pour une bonne compréhension des présentes, il est précisé que l’on entend par : </p>
                        <ul className="list-disc pl-5 mb-4">
                            <li>« CGUV » : désigne les conditions générales d’utilisation et du Site accessibles ici ;</li>
                            <li>« Utilisateur » : désigne toute personne ayant la qualité de Professionnel qui visite une ou plusieurs pages du Site et, plus globalement, utilise le Site et/ou recourt aux Services, la qualité de Professionnel désignant la qualité de Professionnel au sens de l’article liminaire 3° du Code de la consommation, c’est à dire au jour des présentes, « toute personne physique ou morale, publique ou privée, qui agit à des fins entrant dans le cadre de son activité commerciale, industrielle, artisanale, libérale ou agricole, y compris lorsqu'elle agit au nom ou pour le compte d'un autre Professionnel », les Utilisateurs devant avoir cette qualité afin d’accéder aux Services, les Services, de par leur nature, étant réservés aux Utilisateurs ayant la qualité de Professionnel, comme stipulé aux CGUV accessibles <a href="https://infogreffe.mesobligations.fr/fr/inf-conditions-generales" target="_blank">ici</a> ; </li>
                            <li>« Services » : désigne le service identifiés aux CGUV et consistant dans (i) le service d’identification des droits et des obligations légales et réglementaires (« LegalScan ») (ii) le service permettant à un Utilisateur de poser des questions à un Professionnel Référencé et (iii) le service de mise en relation avec un Professionnel Référencé permettant que l’Utilisateur confie à un Professionnel Référencé une prestation dans le cadre d’un accompagnement dans la mise en conformité par un Professionnel Référencé. </li>
                            <li>« Professionnel Référencé » désigne les professionnels référencés sur le Site.</li>
                        </ul>

                        <p className="mb-4">Les autres termes commençant par une majuscule mais n’étant pas définis ci-avant sont définis dans le corps des présentes.</p>
                        <p className="mb-4"></p>
                        <p className="mb-4"></p>

                        <h4 className="text-xl font-semibold mt-6 mb-4">LES DONNÉES A CARACTERE PERSONNEL OBJETS D'UNE COLLECTE</h4>
                        <p>
                            <b>(a)</b> Les données à caractère personnel collectées par Legaware sont les suivantes :
                            (i) les données à caractère personnel que l’Utilisateur fournit en remplissant les formulaires relatifs à la création de son compte et/ou relatifs à son identification,
                            ou en communiquant avec Legaware (par tout moyen de communication ou de communication en ligne, ou en répondant à des questionnaires ou enquêtes de satisfaction)
                            ou en communiquant via le Site avec le Professionnel Référencé (dans le cas où l’Utilisateur souhaite poser une question ou confier une prestation à un Professionnel Référencé).
                            (ii) Ces données concernent le nom, prénom, adresse de messagerie électronique (e-mail), numéro de téléphone, adresse postale.
                            (iii) ainsi que les informations relatives à la relation contractuelle et commerciale entre l'Utilisateur et Legaware et entre l’Utilisateur et le Professionnel Référencé.
                            (iv) L’adresse IP de l’Utilisateur (uniquement lorsque l’Utilisateur clique, concernant une offre d’un Professionnel Référencé, sur le bouton « souscrire » ou « en savoir plus »).
                            (v) Informations de paiement (coordonnées bancaires, numéros de carte bancaire) pour assurer le règlement des Services avant d’en autoriser l'accès.
                        </p>
                        <p>
                            <b>(b)</b> Il est précisé que parmi les données à caractère personnel collectées, certaines sont accessibles aux Professionnels Référencés dans le cadre d’une mise en relation entre l’Utilisateur et le Professionnel Référencé.
                        </p>
                        <p>
                            <b>(c)</b> Les données collectées sont traitées par Legaware et les Professionnels Référencés dans le cadre de leurs responsabilités conjointes de traitement.
                        </p>

                        <h4 className="text-xl font-semibold mt-6 mb-4">INFORMATIONS LÉGALES À DESTINATION DE L’UTILISATEUR ET DE TOUTES PERSONNES CONCERNEES</h4>
                        <p>Les présentes ont pour objet de fournir à l’Utilisateur les informations légales requises.</p>

                        <b><u>(a) Identité et coordonnées des responsables des Traitements </u></b><br /><br />
                        <ul>
                            <li><b>(a.1)</b> La société Legaware, SAS au capital de 1.084 €, siège social 4 Rue des Saussaies, 75008 Paris, RCS Paris 820 434 587.</li>
                            <li><b>(a.2)</b> Les responsables conjoints de traitement sont Legaware et le Professionnel Référencé à qui l’Utilisateur pose une question ou confie une prestation.</li>
                        </ul>

                        <b><u>(b) Coordonnées des délégués à la protection des données</u></b><br /><br />
                        <ul>
                            <li><b>(b.1)</b> M. Joachim Galembert, DPO de Legaware (dpo@legaware.com).</li>
                            <li><b>(b.2)</b> Délégué du Professionnel Référencé, consultable dans la liste disponible ici : <a href="https://infogreffe.mesobligations.fr/fr/les-experts" target="_blank">Liste des experts</a>.</li>
                        </ul>

                        <b><u>(c) Finalités des Traitements</u></b><br /><br />
                        <p>Les finalités des traitements incluent l’identification des Utilisateurs, la mise à disposition du site, la mise en relation avec les Professionnels Référencés, etc.</p>

                        <b><u>(d) Base juridique des Traitements</u></b><br /><br />
                        <p>Les bases légales incluent le consentement, l’exécution de mesures contractuelles, et l’intérêt légitime.</p>


                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PolitiqueConfidentialite;