import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import NumberInputWithSeparator from './NumberInputWithSeparator';
import ContinueButton from './ContinueButton';


const TotalCapitauxPropres = ({ nbStep, isSCI, moveToStep, goBack, totalCapitauxPropres, setTotalCapitauxPropres, nbCandtionMicroValide, raisonSociale, isMicroEntreprise }) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep + 1) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();


    const handleConfirm = () => {

        moveToStep('CapitalSocial');
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);






    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] text-center mb-4 mt-6'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&
                        "Capitaux propres de l'entreprise (en euros) :"
                    }

                    {i18n.language === 'en' &&
                        "Shareholders' equity (in euros) :"
                    }

                </h1>
            </div>

            <NumberInputWithSeparator setValue={setTotalCapitauxPropres} value={totalCapitauxPropres} />
            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(totalCapitauxPropres !== '')}
                    onConfirm={handleConfirm}
                />
            </div>
        </div>
    );
};

export default TotalCapitauxPropres;
