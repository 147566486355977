import React from "react";
import NavigationBar from "../components/NavigationBar";
import Footer from "./Footer";

const MentionsLegales = () => {
    return (
        <div className="flex flex-col bg-white pt-[72px]">
            <NavigationBar />
            <div className="relative flex-grow flex flex-col text-start min-h-96">
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-3xl text-[#1244a0] font-bold mb-6">Mentions Légales</h1>

                    <div className="col-md-12 text-[#919191]" id="texte">
                        <p>
                            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site infogreffe.mesobligations.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
                        </p>

                        <h2 className="text-2xl font-semibold mt-6 mb-4">Edition du site</h2>
                        <p>
                            Le GIE Infogreffe a délégué l'édition et l'exploitation du site infogreffe.mesobligations.fr à la société partenaire Legaware, société par actions simplifiée S.A.S., au capital social de 1 084 €, immatriculée au registre du commerce et des sociétés de Paris sous le n° 820 434 587 et dont le siège social est situé au 4, rue des Saussaies, 75008 - Paris (N° de TVA intracommunautaire : FR 68 820434587).
                        </p>
                        <p>Adresse e-mail de contact : contact [arobase] legaware [point] com</p>
                        <p>Directeur de la publication : Pierre BODIN.</p>

                        <h2 className="text-2xl font-semibold mt-6 mb-4">Hébergeur</h2>
                        <p>
                            Le site infogreffe.mesobligations.fr est hébergé par la société OVH S.A.S., au capital social de 10 069 020 €, immatriculée au registre du commerce et de sociétés de Lille sous le n° 424 761 419 et dont le siège social est situé au 2 rue Kellermann, 59100 Roubaix - France.
                        </p>

                        <h2 className="text-2xl font-semibold mt-6 mb-4">CNIL</h2>
                        <p>
                            La collecte et le traitement informatique des données nominatives par la Société a fait l'objet d'une déclaration normale auprès de l'autorité française de protection des données personnelles (la Commission Nationale de l'Informatique et des Libertés - CNIL) (numéro n° 2016991).
                        </p>

                        <h2 className="text-2xl font-semibold mt-6 mb-4">Conditions générales – Politiques de confidentialités</h2>
                        <p>Les conditions générales d'utilisation et de vente applicables aux Utilisateurs sont accessibles <a href="https://infogreffe.mesobligations.fr/fr/inf-conditions-generales" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">ici</a>.</p>
                        <p>La politique de confidentialité applicable aux Utilisateurs est accessible <a href="https://infogreffe.mesobligations.fr/fr/inf-politique-confidentialite" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">ici</a>.</p>
                        <p>Les conditions générales d'utilisation et de vente applicables aux Professionnels Référencés sont accessibles <a href="https://infogreffe.mesobligations.fr/__library/CGUV_Professionnels_references.pdf" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">ici</a>.</p>
                        <p>La politique de confidentialité applicable aux Professionnels Référencés est accessible <a href="https://infogreffe.mesobligations.fr/fr/inf-politique-confidentialite-professionnels-references" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">ici</a>.</p>
                        <p>Les informations relatives à la qualité des personnes autorisées à déposer une offre de produits ou services, descriptif du service de mise en relation sont accessibles <a href="https://infogreffe.mesobligations.fr/fr/inf-qualite-personnes-autorisees-a-deposer-une-offre" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">ici</a>.</p>
                        <p>Les règles et recommandations concernant la rédaction des offres de produits ou services et l'établissement des contenus par les Professionnels Référencés sont accessibles <a href="https://infogreffe.mesobligations.fr/fr/inf-regles-recommandations-redaction-offre-contenu" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">ici</a>.</p>
                        <p>Les informations relatives aux obligations fiscales et sociales des Professionnels Référencés sont accessibles <a href="https://infogreffe.mesobligations.fr/fr/inf-obligations-fiscales-et-sociales" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">ici</a>.</p>
                        <p>Les informations relatives aux modalités de référencement, déréférencement et de classement des offres de produits ou services par les Professionnels Référencés sont accessibles <a href="https://infogreffe.mesobligations.fr/fr/inf-modalites-referencement" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">ici</a>.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MentionsLegales;