import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import { updateProgressColor } from '../../utils/utils';
import { getEnvConfig } from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import ContinueButton from './ContinueButton';


const OperateurPlateforme = ({ nbStep, isSCI, operateurPlateforme, nbCandtionMicroValide, setOperateurPlateforme, moveToStep, goBack, raisonSociale, isMicroEntreprise,
    isComanyCommercial,
    SIRET,
    codeNaf,
    NAFsection,
    catJuridiqueNiv1,
    catJuridiqueNiv2,
    catJuridiqueNiv3,
    chiffredAffairesTotalNetX,
    nbMoyenSalaries,
    regimeTVANormalSimplifieFranQ,
    optionRegimeMicroEntrepriseQ,
    optionImpotSocieteISQ,

    periodiciteDeclarationCA,
    revenuFiscalReference,
    versementLiberatoireIR,

    SiegeLocauxEnCommunX,
    dateDomiciliationLocauxCommun,

    cocontractantPrestationSup5k,
    versHonorairesComSup1200,
    emissionFactureSecteurPublicQ,
    detentionSiteInternetQ,
    utilisationTraceurCookies,
}) => {
    const rangeRef = useRef(null);
    const [maxValueQuestion, setMaxValueQuestion] = useState(isMicroEntreprise ? ((nbStep >= 9) ? (nbStep) : 9) : isSCI ? ((nbStep >= 18) ? (nbStep + 1) : 18) : (nbStep >= 23) ? (nbStep + 1) : 23);
    const { t, i18n } = useTranslation();
    const [filter, setFilter] = useState('');

    const navigate = useNavigate();

    const decomposeCodeNaf = (code) => {
        const parts = code.match(/^(\d{2})\.(\d{2})([A-Z])$/);
        if (!parts) return null;

        return {
            NAFdivision: parts[1],
            NAFgroupe: `${parts[1]}.${parts[2].charAt(0)}`,
            NAFclasse: `${parts[1]}.${parts[2]}`,
            NAFsousClasse: code
        };
    };


    const handleConfirm = async () => {
        if (isMicroEntreprise) {

            const postData = [{
                scope: {
                    types: ["Obligation"],
                    categories: [
                        "Compliance",
                        "Comptabilite - Gestion - Finances",
                        "Environnement - Developpement durable",
                        "Fiscalite - Impots - Taxes",
                        "Gouvernance - Engagements",
                        "Ressources humaines"
                    ],
                    laws: ["France", "Europe"],
                    modules_only: false,
                    module_id: null
                },
                variables: {
                    SIRET: SIRET,
                    catJuridiqueNiv1: catJuridiqueNiv1,
                    catJuridiqueNiv2: catJuridiqueNiv2,
                    catJuridiqueNiv3: catJuridiqueNiv3,
                    NAFsection: NAFsection,
                    ...decomposeCodeNaf(codeNaf),

                    'resultat[2023].chiffredAffairesTotalNet': Math.ceil(chiffredAffairesTotalNetX), //1
                    'nbMoyenSalaries[2023]': Math.ceil(nbMoyenSalaries), // 2
                    regimeTVANormalSimplifieFranQ: regimeTVANormalSimplifieFranQ, //3
                    optionRegimeMicroEntrepriseQ: optionRegimeMicroEntrepriseQ,
                    optionImpotSocieteISQ: optionImpotSocieteISQ,
                    periodiciteDeclarationCA: periodiciteDeclarationCA,
                    revenuFiscalReference: revenuFiscalReference,
                    versementLiberatoireIR: versementLiberatoireIR,
                    SiegeLocauxEnCommunX: SiegeLocauxEnCommunX,
                    dateDomiciliationLocauxCommun: dateDomiciliationLocauxCommun,
                    versHonorairesComSup1200: versHonorairesComSup1200,
                    emissionFactureSecteurPublicQ: emissionFactureSecteurPublicQ,
                    cocontractantPrestationSup5k: cocontractantPrestationSup5k, //23
                    detentionSiteInternetQ: detentionSiteInternetQ, //25
                    utilisationTraceurCookies: utilisationTraceurCookies, //26
                    operateurPlateforme: operateurPlateforme.includes("*AO") ? operateurPlateforme[0] : operateurPlateforme, // 27

                }
            }];
            console.log(postData);
            setIsLoading(true);
            try {
                const { apiUrl } = getEnvConfig();
                console.log("apiUrl: ", apiUrl + 'run_simulator')
                const response = await fetch(apiUrl + 'run_simulator', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(postData)
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();
                console.log(result)
                if (result?.erros) {
                    alert("Une erreur s'est produite lors du calcul des obligations.")
                    navigate('/questionnaire');
                }
                // navigate('/workspace', { state: { result, raisonSociale } });

                localStorage.setItem('result', JSON.stringify(result));
                localStorage.setItem('raisonSociale', raisonSociale);
                navigate('/workspace');

            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
                alert("Une erreur s'est produite lors du calcul des obligations.")
                navigate('/questionnaire');
            } finally {
                setIsLoading(false);
            }

        } else {
            moveToStep('dateClosed');
        }
    };

    useEffect(() => {
        updateProgressColor(nbStep, maxValueQuestion, rangeRef);
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    const Loader = () => (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
    );


    // Fonction pour ajouter/retirer une option sélectionnée
    const toggleSelectedValue = (key) => {
        setOperateurPlateforme(prevState => {
            if (key === "*AO") {
                if (prevState.includes("*AO")) {
                    return [];
                }
                return ["*AO"];
            }
            if (prevState.includes("*AO")) {
                return [key];
            }

            if (prevState.includes(key)) {
                return prevState.filter(item => item !== key);
            }
            return [...prevState, key];
        });
    };


    const options = [
        { key: 'EACHAT', label: { fr: 'aux utilisateurs de souscrire directement en ligne des biens et/ou services', en: "users to purchase goods and/or services directly online" } },
        {
            key: 'EREF', label: {
                fr: "le classement ou le référencement, au moyen d'algorithmes informatiques, de contenus, de biens ou de services proposés ou mis en ligne par des tiers",
                en: 'the classification or referencing, by means of computer algorithms, of content, goods or services offered or placed online by third parties'
            }
        },
        {
            key: 'LINKING', label: {
                fr: "la mise en relation de plusieurs parties en vue de la vente d'un bien, de la fourniture d'un service ou de l'échange ou du partage d'un contenu, d'un bien ou d'un service",
                en: 'the bringing together of several parties with a view to the sale of a good, the provision of a service or the exchange or sharing of content, a good or a service'
            }
        },
        { key: '*AO', label: { fr: '-- aucune de ces options', en: '-- none of these options' } },
    ];


    const displayedOptions = options.filter(option =>
        option.label.en.toLowerCase().includes(filter.toLowerCase()) ||
        option.label.fr.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div className="flex flex-col items-center justify-start flex-grow p-6 bg-gray-100" style={{ paddingTop: '3%' }}>
            {isLoading && <Loader />}
            <ProgressBar value={nbStep} maxValueQuestion={maxValueQuestion} rangeRef={rangeRef} />


            <div className='max-w-[800px] mb-1'>

                <h1 className='text-2xl text-[#1e4690] my-6'>
                    {i18n.language === 'fr' &&

                        <>
                            Le site internet de {raisonSociale}  permet-il :
                        </>
                    }

                    {i18n.language === 'en' &&
                        <>
                            Does the {raisonSociale} website allow :
                        </>
                    }

                </h1>
            </div>

            <div className='w-full max-w-[500px] p-4 rounded-3xl mb-8'>
                <div className="flex flex-col gap-4">
                    {displayedOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`w-full p-4 text-left font-bold  rounded-xl ${operateurPlateforme.includes(option.key) ? 'bg-[#006acc] text-white' : 'bg-white text-[#006acc] hover:bg-[#f0f0f0] hover:border-[#006acc] hover:border hover:text-[#006acc] '}`}
                            onClick={() => toggleSelectedValue(option.key)}
                        >
                            {i18n.language === 'fr' ? option.label.fr : option.label.en}
                        </button>
                    ))}

                </div>
            </div>

            <div className='max-w-[800px] flex items-center justify-center gap-x-8 mt-8'>
                <button className="border text-[#006acc] px-12 py-3 hover:border-b-2 hover:border-[#006acc] transition rounded-full duration-300" onClick={goBack} >
                    {t('revenu.button_back')}
                </button>
                <ContinueButton
                    isValid={(operateurPlateforme.length !== 0)}
                    onConfirm={handleConfirm}
                    isLastQst={isMicroEntreprise}
                />
            </div>
        </div>
    );
};

export default OperateurPlateforme;
